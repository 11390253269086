<template>
  <b-card-code title="Advance Search Table">
    <b-button
      variant="primary"
      v-b-toggle.sidebar-right
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
    >
      <span class="text-nowrap">Add User</span>
    </b-button>
    <div>
      <b-sidebar
        id="sidebar-right"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        right
        backdrop
        shadow
      >
        <template #default="{ hide }" v-show="isShow">
          <!-- Header -->
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              content-sidebar-header
              px-2
              py-1
            "
          >
            <h5 class="mb-0">Add User</h5>
          </div>
          <validation-observer ref="simpleRules">
            <!-- Body -->
            <b-form class="p-2">
              <b-form-group label="Role" label-for="role">
                <validation-provider
                  #default="{ errors }"
                  name="Role"
                  rules="required"
                >
                  <v-select
                    :options="roleList"
                    :reduce="(val) => val.value"
                    placeholder="Select Role"
                    v-model="user.role_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Name" label-for="name">
                <b-form-input id="name" v-model="user.name" trim />
              </b-form-group>
              <!-- mobile -->
              <div v-if="is_password_show == true">
                <b-form-group label="Mobile Number" label-for="login-mobile">
                  <validation-provider
                    #default="{ errors }"
                    name="Mobile"
                    vid="mobile"
                    rules="required"
                  >
                    <!--                      <b-form-input
                          id="login-mobile"
                          v-model="userMobile"
                          :state="errors.length > 0 ? false:null"
                          name="login-email"
                          placeholder="Type Mobile Number"
                      />-->
                    <VuePhoneNumberInput
                      id="mobile"
                      v-model="user.mobile"
                      default-country-code="BD"
                      clearable
                      @update="onUpdate"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <b-form-group label="Email" label-for="email">
                <b-form-input id="email" v-model="user.email" trim />
              </b-form-group>
              <div v-if="is_password_show == true">
                <b-form-group label="Password" label-for="a-password">
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="Password"
                    rules="required|min:8|password"
                  >
                    <b-form-input
                      id="a-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      type="password"
                      placeholder="Password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- confirm password -->
                <b-form-group label="Confirm Password" label-for="ac-password">
                  <validation-provider
                    #default="{ errors }"
                    name="Confirm Password"
                    rules="required|confirmed:Password"
                  >
                    <b-form-input
                      id="ac-password"
                      v-model="cpassword"
                      :state="errors.length > 0 ? false : null"
                      type="password"
                      placeholder="Confirm Password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <b-form-group label="Status" label-for="status">
                <validation-provider
                  #default="{ errors }"
                  name="Status"
                  rules="required"
                >
                  <v-select
                    :options="statusOptions"
                    :reduce="(val) => val.value"
                    placeholder="Select Status"
                    v-model="user.status_value"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  @click.prevent="validationForm"
                  @click="hide"
                >
                  Send
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="hide"
                  @click.prevent="closeSideBar"
                >
                  Cancel
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </template>
      </b-sidebar>
    </div>
    <div class="custom-search">
      <!-- advance search input -->
      <validation-observer ref="simpleRulesSearch">
        <b-form>
          <b-row>
            <b-col md="4">
              <label for="isp_id">ISP Name</label>
              <validation-provider
                #default="{ errors }"
                name="ISP Name"
                rules="required"
              >
                <v-select
                  :options="isp_list"
                  :reduce="(val) => val.value"
                  placeholder="Select ISP"
                  v-model="isp_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <label for="status">Status</label>
              <validation-provider
                #default="{ errors }"
                name="Status"
                rules="required"
              >
                <v-select
                  :options="statusOptions"
                  :reduce="(val) => val.value"
                  placeholder="Select Status"
                  v-model="status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for=""> </label>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="info"
                  @click.prevent="searchInvoiceSettlement"
                >
                  Search
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      theme="my-theme"
    >
      <!-- Column: Actions -->
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'name'" class="text-nowrap">
          <span>{{ props.row.name }}</span>
        </span>
        <span v-if="props.column.field == 'email'" class="text-nowrap">
          <span>{{ props.row.email }}</span>
        </span>
        <span v-if="props.column.field == 'mobile'" class="text-nowrap">
          <span>{{ props.row.mobile }}</span>
        </span>
        <span v-if="props.column.field == 'countryCode'" class="text-nowrap">
          <span>{{ props.row.countryCode }}</span>
        </span>
        <span v-if="props.column.field == 'total_isp_Ppp'" class="text-nowrap">
          <span>{{ props.row.total_isp_Ppp }}</span>
        </span>
        <span v-if="props.column.field == 'role_name'" class="text-nowrap">
          <span>{{ props.row.role_name }}</span>
        </span>
        <span v-if="props.column.field == 'address'" class="text-nowrap">
          <span>{{ props.row.address }}</span>
        </span>
        <span v-if="props.column.field == 'status'" class="text-nowrap">
          <span class="text-success">{{ props.row.status }}</span>
        </span>
        <div class="text-nowrap" v-if="props.column.field == 'actions'">
          <feather-icon
            icon="EditIcon"
            size="16"
            class="mx-1"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-toggle.sidebar-right
            @click.prevent="userUpdateIdSet(props.row.id)"
          />
        </div>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BLink,
  BTooltip,
  BMedia,
  BBadge,
  BButton,
  VBToggle,
  BSidebar,
  BForm,
  BFormTextarea,
} from "bootstrap-vue";
import {
  ISP_LIST,
  ADMIN_USER_EDIT,
  ADMIN_USER_LIST,
  ADMIN_ROLELIST,
} from "@core/services/api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
// import store from '@/store/index'
import store from "@/store";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import useJwt from "@/auth/jwt/useJwt";
import vSelect from "vue-select";
import { required, email } from "@validations";
import { checkRes } from "@core/services/helper";
// Use toast
import Mixin from "@core/services/mixin";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  mixins: [Mixin],
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BLink,
    BTooltip,
    BMedia,
    BBadge,
    BButton,
    BSidebar,
    BForm,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    VuePhoneNumberInput,
  },
  data() {
    const statusOptions = [
      { value: 1, label: "Active" },
      { value: 0, label: "Inactive" },
    ];
    const resolveClientAvatarVariant = (status) => {
      if (status === "Partial Payment") return "primary";
      if (status === "Inactive") return "danger";
      if (status === "Downloaded") return "secondary";
      if (status === "Draft") return "warning";
      if (status === "Sent") return "info";
      if (status === "Active") return "success";
      return "primary";
    };
    return {
      is_password_show: true,
      roleList: [],
      statusOptions,
      toggle: false,
      isShow: false,
      pageLength: 10,
      dir: false,
      required,
      columns: [
        { field: "name", label: "Name", sortable: true },
        { field: "email", label: "Email", sortable: true },
        { field: "mobile", label: "Mobile", sortable: true },
        { field: "role_name", label: "Role", sortable: true },
        {
          field: "address",
          label: "Address",
          sortable: true,
        },
        {
          field: "status",
          label: "Status",
        },
        { field: "actions", label: "Action" },
      ],
      countryCallingCode: null,
      countryCode: null,
      rows: [],
      invoice_ids: [],
      isp_list: [],
      isp_id: "",
      searchTerm: "",
      cpassword: null,
      password: null,
      updateStatus: "",
      UpdateBankTransTd: null,
      userId: "",
      status: "",
      user: {
        name: "",
        email: "",
        mobile: "",
        phoneNumber: "",
        status_value: "",
        role_id: "",
      },
      mobile: "",
      resolveClientAvatarVariant,
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.listSettlementData();
    this.listRoleData();
  },
  methods: {
    onUpdate(payload) {
      this.countryCallingCode = payload.countryCallingCode;
      this.countryCode = payload.countryCode;
      this.phoneNumber = payload.nationalNumber;
      this.isValidMobile = payload.isValid;
    },
    listRoleData() {
      this.$http
        .get(process.env.VUE_APP_BASEURL + ADMIN_ROLELIST, {
          params: {},
        })
        .then((res) => {
          this.roleList = res.data.data;
        });
    },
    listSettlementData() {
      this.$http
        .get(process.env.VUE_APP_BASEURL + ADMIN_USER_LIST, {
          params: {},
        })
        .then((res) => {
          this.rows = res.data.data.users;
        });
    },
    closeSideBar() {
      this.is_password_show = true;
      this.userId = null;
      this.user = "";
    },
    userUpdateIdSet(id) {
      this.is_password_show = false;
      this.userId = id;
      this.$http
        .get(process.env.VUE_APP_BASEURL + ADMIN_USER_EDIT + this.userId, {
          params: {},
        })
        .then((res) => {
          this.user = res.data.data.user;
        });
    },
    searchInvoiceSettlement() {
      this.$refs.simpleRulesSearch.validate().then((success) => {
        if (success) {
          this.$http
            .get(process.env.VUE_APP_BASEURL + ADMIN_USER_LIST, {
              params: {
                status: this.status,
                isp_id: this.isp_id,
                bank_trans_id: this.bank_trans_id,
                from_date: this.from_date,
                to_date: this.to_date,
              },
            })
            .then((res) => {
              this.rows = res.data.data.isp_info;
            });
        }
      });
    },
    idpListData() {
      this.$http
        .get(process.env.VUE_APP_BASEURL + ISP_LIST)
        .then((res) => {
          this.isp_list = res.data.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            console.log(error);
          }
        });
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          useJwt
            .addUserRoleWise({
              user_id: this.userId ?? null,
              name: this.user.name ?? null,
              email: this.user.email ?? null,
              mobile:
                this.countryCallingCode + this.phoneNumber ?? this.user.mobile,
              countryCode: this.countryCode ?? null,
              role_id: this.user.role_id ?? null,
              password: this.password,
              password_confirmation: this.cpassword,
              status: this.user.status_value ?? 1,
            })
            .then((response) => {
              this.listSettlementData();
              if (checkRes(response.status) && response.data.data) {
                this.isShow = false;
                this.$router
                  .push({ name: "admin-user-list" })
                  .catch((success) => {
                    this.toastMessage(
                      "success",
                      "User Add Successfully",
                      response
                    );
                  });
              } else {
                this.$router
                  .push({ name: "admin-user-list" })
                  .then(() => {
                    this.toastMessage("warning", "Something Wrong", response);
                  })
                  .catch((error) => {
                    this.toastMessage("danger", "Something Wrong", response);
                  });
              }
            });
        }
      });
    },
  },
  mounted() {
    this.idpListData();
    this.is_password_show = true;
  },
};
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
